import { UserIdentifier } from "./UserIdentifier";

/**
 * Class which uses ECN as the user identifier
 */
export class EcnIdentifier extends UserIdentifier {
    IndentifierKey = "PATIENT_ECN";

    public constructor(
        public ecn: string
    ) {
        super(ecn);
    }
 }
