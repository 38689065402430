<div id="header" class="o-mobile-page__header">
  <div class="c-table">
    <div class="c-table__cell valign-bottom">
      <h2 class="c-heading-mobile">My health profile</h2>
    </div>
  </div>
</div>

<div class="o-mobile-page">
  <div class="o-mobile-page__body has-header is-full-height">
    <div class="c-survey">
      <div class="c-survey__row">
        <div class="c-survey__top valign-middle">
          <h2 class="c-survey__question">{{ 'page-not-found' | translate }}</h2>
        </div>
      </div>
    </div>
  </div>
</div>
