import { UserIdentifier } from "./UserIdentifier";

/**
 * Class which uses the myAir patient ID as the user identifier
 */
export class PatientIdIdentifier extends UserIdentifier {
    IndentifierKey = "PATIENT_ID";

    public constructor(
        public patientId: string
    ) {
        super(patientId);
    }
 }
