import { Component, OnInit } from "@angular/core";
import {Bmi} from "../../model/Bmi";
import {UserIdentity} from "../../model/UserIdentity";
import {Constants} from "../../utils/constants";
import {FileManagerProvider} from "../../providers/file-manager/file-manager";
import {ActivatedRoute, Router} from "@angular/router";
import {WebSurveyProvider} from "../../providers/web-service/webservice-survey";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {TranslationProvider} from "../../providers/translation/translation";
import {AnswerSaveActions, ResponseType} from "../../utils/enums";
import {SurveyResponseWrapper} from "../../model/SurveyResponseWrapper";
import {SurveyResponse} from "../../model/SurveyResponse";
import {SurveyQuestion} from "../../model/SurveyQuestion";
import {SurveySource} from "../../model/SurveySource";
import {SurveyEnumAnswer} from "../../model/SurveyEnumAnswer";
import {SurveyStringAnswer} from "../../model/SurveyStringAnswer";
import {SurveyAnswer} from "../../model/SurveyAnswer";
import {SurveyNumberAnswer} from "../../model/SurveyNumberAnswer";
import {SurveyDisplayEvent} from "../../model/SurveyDisplayEvent";
import {EnvService} from "../env.service";

// import the javascript functions to allow typescript invocation
declare var postSurveyResponse;
declare var declineSurvey;
declare var storeSurveyResponse;
declare var isJavascriptInjectedIntoWebView;

@Component({
  selector: "app-survey-base",
  templateUrl: "./survey-base.component.html",
  styleUrls: ["./survey-base.component.scss"]
})
export class SurveyBaseComponent implements OnInit {
  surveyPath = "../assets/survey-templates";
  surveyObject: any = null;
  patientBmi: Bmi = new Bmi();
  identity: UserIdentity = null;
  valid = true;
  showHeader = false;
  language: string = null;
  countryId: string;
  patientId: string;
  serial: string;
  surveyId: string; // The sleep con survey ID we are presenting
  surveyAuthIdentifier: string; // The identifier provided by sleep con for validation
  surveyDisplayId: string; // Matches a JSON file defining the questions to present
  baselineResponse: string;
  dayOffset: number;
  currentQuestion: any;
  currentQuestionIndex = 0;
  selectedAnswer: any;
  continueText: string;
  cancelText: string;
  selectedText: string;
  engagementCardOnly = false;
  useWebView = false;
  recentDeclined = false;
  usePush = false;
  showEngagementCard = false;
  showSurveyQuestions = false;
  showPositiveDialog = false;
  showNegativeDialog = false;
  engagementCardObj: any = {};
  dialogObj: any = {};
  progressBarStyle: string;
  ariaValueNow: string;
  validationTextStringId = "please-select-option";
  pageHeaderTextStringId = "care-checkin";
  skipTextStringId = "skip";
  sleepConciergeSurveyId = "sleep-concierge-v1";
  useYourDeviceAS11SleepLibraryId = 65;
  adjustRampTimeAS11SleepLibraryId = 59;
  scheduleStartDateForExperiencedUsers = 208;
  getUsedToTherapyAnswerId = "getting-used-to-therapy";
  breathOptions = ["breath-in", "breath-out"];
  baselineQuestionId = "how-sleepy-prior-to-therapy";

  surveyAnswerCoachingLibraryActionMapper = {
    "sleep-library": Constants.ExitToSleepLibraryAction,
    "dashboard": Constants.ExitToDashboardAction,
    "device": Constants.ExitToCoachingLibraryEquipmentAction,
    "mask": Constants.ExitToCoachingPTAMaskSetupAction,
    "getting-used-to-therapy": Constants.ExitToCoachingPTATestDriveAction,
    "breath-in": Constants.ExitToCoachingLibraryEquipmentAction,
    "breath-out": Constants.ExitToCoachingLibraryEquipmentAction
  };

  microCoachingMapper = {
    extremely: {
      extremely: "ongoing-follow-up-patient-story-1-insight",
      very: "ongoing-follow-up-patient-story-1-insight",
      moderately: "amazing",
      slightly: "amazing",
      notatall: "amazing"
    },
    very: {
      extremely: "ongoing-follow-up-patient-story-2-insight",
      very: "ongoing-follow-up-patient-story-1-insight",
      moderately: "amazing",
      slightly: "amazing",
      notatall: "amazing"
    },
    moderately: {
      extremely: "ongoing-follow-up-patient-story-2-insight",
      very: "ongoing-follow-up-patient-story-2-insight",
      moderately: "ongoing-follow-up-patient-story-1-insight",
      slightly: "amazing",
      notatall: "amazing"
    },
    slightly: {
      extremely: "ongoing-follow-up-patient-story-2-insight",
      very: "ongoing-follow-up-patient-story-2-insight",
      moderately: "ongoing-follow-up-patient-story-2-insight",
      slightly: "default-insight",
      notatall: "default-insight"
    },
    notatall: {
      extremely: "ongoing-follow-up-patient-story-2-insight",
      very: "ongoing-follow-up-patient-story-2-insight",
      moderately: "ongoing-follow-up-patient-story-2-insight",
      slightly: "default-insight",
      notatall: "default-insight"
    },
    default: {
      extremely: "ongoing-follow-up-patient-story-2-insight",
      very: "ongoing-follow-up-patient-story-2-insight",
      moderately: "ongoing-follow-up-patient-story-2-insight",
      slightly: "default-insight",
      notatall: "default-insight"
    },
  };

  constructor(
    protected environmentConfig: EnvService,
    protected route: ActivatedRoute,
    private router: Router,
    private fileManager: FileManagerProvider,
    private webInterface: WebSurveyProvider,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private localeMapper: TranslationProvider
  ) {
  }

  ngOnInit(): void {
    this.spinner.show();
    this.readRouteParams();
    this.readIdentity();
    this.setLanguage();
    this.loadSurvey();
  }

  readRouteParams(): void {
    this.surveyId = this.route.snapshot.paramMap.get("surveyId");
    this.dayOffset = +this.route.snapshot.paramMap.get("dayOffset");
    this.surveyDisplayId = this.route.snapshot.paramMap.get("surveyDisplayId");
    this.surveyAuthIdentifier = this.route.snapshot.paramMap.get("surveyAuthIdentifier");
    this.baselineResponse = this.route.snapshot.paramMap.get("baselineResponse");

    if (this.baselineResponse) {
      this.baselineResponse = this.parseBaselineResponse(this.baselineResponse);
    }

    if (isNaN(this.dayOffset) || this.dayOffset < 0) {
      // day is not a valid value, redirect to not found.
      this.redirectToNotFound();
    }
  }

  /**
   * Read the identity fields so we know
   * who the user is
   */
  readIdentity(): void {
    this.patientId = this.readFromQueryString("patientId");
    this.language = this.readFromQueryString("language", false);
    this.serial = this.readFromQueryString("serial");
    const ecn = this.readFromQueryString("ecn", false);
    this.useWebView = this.readFromQueryString("appVersion", false) !== null;
    this.usePush = this.readFromQueryString("push", false) !== null;
    this.recentDeclined = this.readFromQueryString("decline", false) !== null;
    this.identity = new UserIdentity(this.serial, this.patientId, ecn);
    this.showHeader = this.useWebView || this.usePush;
    this.countryId = this.readFromQueryString("countryId", false);
  }

  /**
   * Sets the language based on the param passed in the query string
   */
  setLanguage(): void {
    if (this.language === null) {
      return;
    }

    const locale = this.localeMapper.getLocale(this.language);

    if (locale !== null) {
      this.translate.use(locale);
    }
  }

  /**
   * Reads a value from the query string.
   * @param paramName The name of the querystring parameter.
   * @param required (Optional) Whether the param is required. Defaults
   * to true.
   */
  readFromQueryString(paramName: string, required: boolean = true): string {
    const value = this.route.snapshot.queryParamMap.get(paramName);

    if (required && !value) {
      console.error(`Error: Required querystring key "${paramName}" missing from request URL`);
      this.redirectToNotFound();
    }

    return value;
  }

  /**
   * Loads the survey definition based on data passed into the app
   * display to the user.
   */
  loadSurvey(): void {
    const surveyJsonPath = `${this.surveyPath}/${this.surveyId}/${this.surveyDisplayId.toLowerCase()}.json`;

    this.fileManager.getJSONDataAsync(surveyJsonPath).then(data => {
      console.log(`Loading survey object from path ${surveyJsonPath}`);
      this.surveyObject = data;
      if (this.useWebView && (!this.usePush || this.surveyDisplayId.toLowerCase() === "selfhelpfollowup")) {
        this.setupEngagementCard();
      } else {
        this.setupSurveyQuestions();

        if (!this.showSurveyQuestions) {
          this.setupDialog();
        }
      }
    })
      .catch(error => {
        console.error(`Error loading survey content: ${error}`);
        this.redirectToNotFound();
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  answerSelected(answer: any): void {
    this.resetSelection();

    if (!answer) {
      this.validationTextStringId = this.currentQuestion.Template !== "BMI" ? "please-select-option" : "input-all-fields";
      this.valid = false;
      this.selectedAnswer = null;
      return;
    }

    if (answer.FreeText && answer.Description?.trim() === "") {
      this.validationTextStringId = "enter-description";
      this.selectedAnswer = null;
      return;
    }

    this.valid = true;
    this.selectedAnswer = answer;
    this.surveyObject.Questions[this.currentQuestionIndex].SelectedAnswer = answer;
    this.updatePageText();
  }

  updatePageText(): void {
    this.selectedText = this.getAnswerSelectedText(this.selectedAnswer);

    if (this.selectedAnswer.ContinueText) {
      this.continueText = this.selectedAnswer.ContinueText;
    } else {
      this.continueText = this.currentQuestion.DefaultContinueText;
    }

    if (this.selectedAnswer.CancelText) {
      this.cancelText = this.selectedAnswer.CancelText;
    } else {
      this.cancelText = this.getDefaultCancelText();
    }
  }

  getAnswerSelectedText(answer: any): string {
    if (answer.SelectedText === "conditional-microcoaching") {
      return this.getMicroCoachingStringId(answer);
    }

    return answer.SelectedText;
  }

  continueSelected(): void {
    if (this.useWebView) {
      if (this.showEngagementCard) {
        this.showEngagementCard = false;
        this.resetButtonsText();

        if (this.engagementCardOnly) {
          postSurveyResponse();
        }

        this.setupSurveyQuestions();

        if (!this.showSurveyQuestions) {
          this.setupPositiveDialog();
        }
      } else if (this.showSurveyQuestions) {
        this.handleQuestionContinueAction();
      } else if (this.showPositiveDialog || this.showNegativeDialog) {
        const navigateTo = this.dialogObj.PositiveButton.NavigateTo;
        const action = this.surveyAnswerCoachingLibraryActionMapper[navigateTo];
        this.navigate(action);
      }
    } else if (this.showSurveyQuestions) {
      this.handleQuestionContinueAction();
    } else {
      this.handlePositiveDialogAction();
    }
  }

  cancelSelected(): void {
    if (this.useWebView) {
      if (this.showEngagementCard) {
        this.resetButtonsText();
        this.showEngagementCard = false;
        this.setupNegativeDialog();
        const action = !this.engagementCardOnly ? Constants.ExitToDashboardAction : null;
        this.handleSurveyDecline(action);
      } else if (this.showNegativeDialog || this.showPositiveDialog) {
        const navigateTo = this.dialogObj.NegativeButton.NavigateTo;
        const action = this.surveyAnswerCoachingLibraryActionMapper[navigateTo];
        this.navigate(action);
      } else if (this.showSurveyQuestions) {
        if (this.isQuestionDismissible()) {
          if (this.recentDeclined) {
            this.setupNegativeDialog();
            this.currentQuestion = null;
          }
          this.handleSurveyDecline(Constants.ExitToDashboardAction);
        }
        else {
          this.handleQuestionNegativeAction();
        }
      } else {
        this.currentQuestion = null;
        this.resetButtonsText();
        this.setupNegativeDialog();
      }
    } else if (this.showSurveyQuestions) {
      this.handleQuestionNegativeAction();
    } else {
      this.handleNegativeDialogAction();
    }
  }

  handleQuestionContinueAction(): void {
    if (!this.selectedAnswer) {
      this.valid = false;
      return;
    }

    let currentQuestion = this.surveyObject.Questions[this.currentQuestionIndex];
    currentQuestion.answerTime = new Date().toISOString();

    // Currently submitting one answer at a time instead of batches,
    // but ideally would like to do a batch submit once we can handle
    // some error cases like losing network connectivity
    switch (this.selectedAnswer.Action) {
      case AnswerSaveActions[AnswerSaveActions.Submit]:
        this.submitAnswerAndQuit(Constants.ExitToDashboardAction);
        return;
      case AnswerSaveActions[AnswerSaveActions.Continue]:
        this.storeAnswers();
        this.nextQuestion();
        this.selectedAnswer = null;
        break;
      case AnswerSaveActions[AnswerSaveActions.SleepLibrary]:
        let action = Constants.ExitToSleepLibraryAction;

        if (this.useWebView) {
          if (this.surveyId === this.sleepConciergeSurveyId) {
            action = this.surveyAnswerCoachingLibraryActionMapper[this.selectedAnswer.AnswerId];

            if (this.selectedAnswer.AnswerId === "device") {
              action += `/${this.useYourDeviceAS11SleepLibraryId}`;
            }
            else if (["breath-in", "breath-out"].includes(this.selectedAnswer.AnswerId)) {
              action += `/${this.adjustRampTimeAS11SleepLibraryId}`;
            }
          }
        }

        this.submitAnswerAndQuit(action);
        break;
      default:
        // no action specified, default is just submit and quit
        this.submitAnswerAndQuit(Constants.ExitToDashboardAction);
        break;
    }
  }

  handleQuestionNegativeAction(): void {
    if (!this.selectedAnswer) {
      this.valid = false;
      return;
    }
    let currentQuestion = this.surveyObject.Questions[this.currentQuestionIndex];
    currentQuestion.answerTime = new Date().toISOString();
    this.submitAnswerAndQuit(Constants.ExitToDashboardAction);
  }

  submitAnswerAndQuit(redirectAction: string): void {
    console.log(`Submit and starting action ${redirectAction}. Session ends here.`);
    this.spinner.show();
    let successCallback = () => {};
    let failedCallback = () => {};

    if (!isJavascriptInjectedIntoWebView()){
      successCallback = () => {
        this.selectedAnswer = null;
        // trigger to notify the app that the session is ended.
        window.location.href = redirectAction;
      };
      failedCallback = () => {
        window.location.href = redirectAction;
      };
    }

    this.submitAnswer(redirectAction)
      .then(successCallback)
      .catch(failedCallback);
  }

  nextQuestion(): void {
    this.currentQuestion = this.surveyObject.Questions[++this.currentQuestionIndex];

    if (this.currentQuestion != null) {
      if (this.currentQuestion.hasOwnProperty("PossibleAnswers")){
        this.currentQuestion.PossibleAnswers = this.currentQuestion.PossibleAnswers.filter(p => !this.shouldHideOption(p));
      }

      this.validationTextStringId = this.currentQuestion.Template !== "BMI" ? "please-select-option" : "input-all-fields";
      this.continueText = this.currentQuestion.DefaultContinueText;
      this.cancelText = this.currentQuestion.DefaultCancelText;
      this.selectedText = "";
      this.submitSurveyDisplayEvent();
    }

    this.setProgressBar();
  }

  previousQuestion(): void {
    this.currentQuestion = this.surveyObject.Questions[--this.currentQuestionIndex];
    this.validationTextStringId = this.currentQuestion.Template !== "BMI" ? "please-select-option" : "input-all-fields";
    this.continueText = this.currentQuestion.DefaultContinueText;
    this.cancelText = this.currentQuestion.DefaultCancelText;
    if (this.currentQuestion.hasOwnProperty("SelectedAnswer")){
      this.selectedAnswer = this.currentQuestion.SelectedAnswer;
      this.selectedText = this.getAnswerSelectedText(this.selectedAnswer);
    }
    this.submitSurveyDisplayEvent();
    this.setProgressBar();
    this.valid = true;
  }

  submitAnswer(action = null): Promise<any> {
    const surveyResponse = this.generateSurveyResponse();
    console.log(`Submitting answers for survey "${this.surveyId}" and user "${this.identity}"`);

    // The new flow posts the survey response via injected javascript interface
    if (isJavascriptInjectedIntoWebView()){
      return postSurveyResponse(surveyResponse, action);
    }

    // The legacy flow posts the survey response via API Gateway
    return this.webInterface.saveAnswers(
      surveyResponse,
      this.patientId,
      this.surveyAuthIdentifier,
      this.surveyId)
      .toPromise().catch(err => this.handlException(err));
  }

  // send the all the questions answered so far to store them in the app without making the network call
  storeAnswers(): void {
    const surveyResponse = this.generateSurveyResponse();

    if (isJavascriptInjectedIntoWebView()) {
      storeSurveyResponse(surveyResponse);
    }
  }

  generateSurveyResponse(): SurveyResponseWrapper {
    const response = new SurveyResponse();
    response.surveyId = this.surveyId;
    response.sourceId = this.identity.generateSourceId();

    if (this.surveyDisplayId.toLowerCase() === "selfhelpfollowup") {
      response.surveyId = this.surveyId + "#" + this.surveyDisplayId + "#" + this.dayOffset;
    }

    // submit questions as a batch
    this.surveyObject.Questions.forEach(q => {
      if (q.hasOwnProperty("SelectedAnswer") && q.SelectedAnswer !== null) {
        const answer = q.SelectedAnswer;
        if (answer.ResponseType === ResponseType.Bmi) {
          // BMI has specific handling to submit each unit as a different survey answer.
          response.questions = response.questions.concat(this.formatBmiResponse(answer));
        }
        else {
          const question = new SurveyQuestion();
          question.questionId = q.QuestionId;
          question.dayOffset = this.dayOffset;
          question.source = new SurveySource(this.identity);

          if (q.answerTime !== null) {
            question.dateTime = q.answerTime;
          }

          switch (answer.ResponseType){
            case ResponseType.Bmi:
              break;
            case ResponseType.FreeText:
              question.answers.push(new SurveyEnumAnswer(answer.AnswerId));
              question.answers.push(new SurveyStringAnswer(answer.Description?.trim()));
              break;
            case ResponseType.MultiSelect:
              question.answers = this.formatMultiSelectResponses(answer.Answers);
              break;
            default:
              question.answers.push(new SurveyEnumAnswer(answer.AnswerId));
              break;
          }

          response.questions.push(question);
        }
      }
    });

    return new SurveyResponseWrapper(response);
  }

  /**
   * Handles formatting for multiselect responses
   * @param selectedAnswers: The answers provided by the user
   * @returns The answers formatted for submition to SleepCon
   */
  formatMultiSelectResponses(selectedAnswers: any[]): SurveyAnswer[] {
    const surveyAnswers: SurveyAnswer[] = [];

    selectedAnswers.forEach(answer => {
      if (answer.ResponseType === ResponseType.FreeText) {
        surveyAnswers.push(new SurveyEnumAnswer(answer.AnswerId));
        surveyAnswers.push(new SurveyStringAnswer(answer.Description?.trim()));
      } else {
        surveyAnswers.push(new SurveyEnumAnswer(answer.AnswerId));
      }
    });

    return surveyAnswers;
  }

  formatBmiResponse(bmiAnswer: any): SurveyQuestion[] {
    const bmiResponses: SurveyQuestion[] = [];
    const responseDateTime = new Date().toISOString();

    bmiResponses.push(this.generateBmiQuestion(responseDateTime, Bmi.WeightQuestionId, bmiAnswer.AnswerId.weight));
    bmiResponses.push(this.generateBmiQuestion(responseDateTime, Bmi.HeightQuestionId, bmiAnswer.AnswerId.height));
    bmiResponses.push(this.generateBmiQuestion(responseDateTime, Bmi.BmiResultQuestionId, bmiAnswer.AnswerId.bmi));
    return bmiResponses;
  }

  generateBmiQuestion(responseDateTime: string, questionId: string, answer: number): SurveyQuestion {
    const weightQuestion = new SurveyQuestion(responseDateTime);
    weightQuestion.questionId = questionId;
    weightQuestion.dayOffset = this.dayOffset;
    weightQuestion.source = new SurveySource(this.identity);
    weightQuestion.answers.push(new SurveyNumberAnswer(answer));

    return weightQuestion;
  }

  /**
   * Redirect site to page not found to
   * handle error cases
   */
  redirectToNotFound(): void {
    // home page will display not found, as this app is only designed
    // to display specifc items based on the URL provided
    this.router.navigate(["/"]);
  }

  /* tslint:disable:no-string-literal */
  getMicroCoachingStringId(answer: any): string {
    if (!answer) {
      return "";
    }

    const answerId = this.stripInvalidCharsFromAnswerId(answer.AnswerId);

    if (!this.baselineResponse) {
      return this.getDefaultMicroCoachingId(answerId);
    }

    let stringId;

    try {
      stringId = this.microCoachingMapper[this.baselineResponse][answerId];
    } catch (error) {
      stringId = this.getDefaultMicroCoachingId(answerId);
    }

    return stringId;
  }

  getDefaultMicroCoachingId(answerId: string): string {
    return this.microCoachingMapper.default[answerId];
  }

  /* tslint:enable:no-string-literal */

  parseBaselineResponse(baselineResponseInput: string): string {
    if (!baselineResponseInput) {
      return null;
    }

    const parsedBaselineResponse = this.stripInvalidCharsFromAnswerId(baselineResponseInput.toLowerCase());

    if (!this.microCoachingMapper.hasOwnProperty(parsedBaselineResponse)) {
      return null;
    }

    return parsedBaselineResponse;
  }

  stripInvalidCharsFromAnswerId(answerId: string): string {
    if (!answerId) {
      return "";
    }

    return answerId.replace(/-/g, "");
  }

  setupSurveyQuestions(): void {
    if (this.surveyObject.hasOwnProperty("Questions")) {
      this.surveyObject.Questions = this.surveyObject.Questions.filter(q => !this.shouldHideQuestion(q));
      this.surveyObject.Questions[0].DefaultCancelText = "no-thanks";
      this.currentQuestion = this.surveyObject.Questions[this.currentQuestionIndex];
      this.continueText = this.currentQuestion.DefaultContinueText;
      this.cancelText = this.getDefaultCancelText();
      this.selectedAnswer = null;
      this.valid = true;
      this.showSurveyQuestions = true;

      if (this.currentQuestion.hasOwnProperty("PossibleAnswers")) {
        this.currentQuestion.PossibleAnswers = this.currentQuestion.PossibleAnswers.filter(p => !this.shouldHideOption(p));
      }
      this.submitSurveyDisplayEvent();
    }
  }

  setupEngagementCard(): void {
    if (this.surveyObject.hasOwnProperty("EngagementCard") && !this.skipEngagementCard()) {
      this.engagementCardObj = this.surveyObject.EngagementCard;

      /** If this is a self help follow up card, retrieve the self help answer to determine correct description **/
      if (this.surveyObject.EngagementCard.TherapyDescription != null) {
        const selfHelpAnswer = this.readFromQueryString("answer", true);
        if (selfHelpAnswer === "therapy") {
          this.engagementCardObj.Description = this.engagementCardObj.TherapyDescription;
        }
        else if (selfHelpAnswer === "mask") {
          this.engagementCardObj.Description = this.engagementCardObj.MaskDescription;
        }
        else if (selfHelpAnswer === "device") {
          this.engagementCardObj.Description = this.engagementCardObj.DeviceDescription;
        }
        else if (["breath-in", "breath-out"].includes(selfHelpAnswer)) {
          this.engagementCardObj.Description = this.engagementCardObj.BreathDescription;
        }
      }

      this.continueText = this.engagementCardObj.PositiveButton;
      this.cancelText = this.engagementCardObj.NegativeButton;
      this.valid = true;
      this.showEngagementCard = true;
      this.engagementCardOnly = this.surveyObject.hasOwnProperty("EngagementCardOnly");
    } else {
      this.setupSurveyQuestions();
    }
  }

  setupPositiveDialog(): void {
    this.resetButtonsText();
    if (this.surveyObject.hasOwnProperty("PositiveDialog")) {
      this.dialogObj = this.surveyObject.PositiveDialog;
      if (this.dialogObj.hasOwnProperty("PositiveButton")) {
        this.continueText = this.dialogObj.PositiveButton.Text;
      }
      if (this.dialogObj.hasOwnProperty("NegativeButton")) {
        this.cancelText = this.dialogObj.NegativeButton.Text;
      }

      this.showPositiveDialog = true;
      this.valid = true;
    }
  }

  setupNegativeDialog(): void {
    this.resetButtonsText();
    if (this.surveyObject.hasOwnProperty("NegativeDialog")) {
      this.dialogObj = this.surveyObject.NegativeDialog;
      if (this.dialogObj.hasOwnProperty("PositiveButton")) {
        this.continueText = this.dialogObj.PositiveButton.Text;
      }
      if (this.dialogObj.hasOwnProperty("NegativeButton")) {
        this.cancelText = this.dialogObj.NegativeButton.Text;
      }

      if (this.dialogObj.hasOwnProperty("RequireLastDecline")) {
        this.showNegativeDialog = this.dialogObj.RequireLastDecline && this.recentDeclined;
      } else {
        this.showNegativeDialog = true;
      }

      if (!this.showNegativeDialog){
        this.resetButtonsText();
      }

      this.showSurveyQuestions = false;
      this.valid = true;
    }
  }

  resetButtonsText(): void {
    this.continueText = null;
    this.cancelText = null;
  }

  navigate(action: string): void {
    window.location.href = action;
  }

  handlePositiveDialogAction(): void {
    const navigateTo = this.dialogObj.PositiveButton.NavigateTo;
    const action = this.surveyAnswerCoachingLibraryActionMapper[navigateTo];
    this.navigate(action);
  }

  handleNegativeDialogAction(): void {
    const navigateTo = this.dialogObj.NegativeButton.NavigateTo;
    const action = this.surveyAnswerCoachingLibraryActionMapper[navigateTo];
    this.navigate(action);
  }

  handleSurveyDecline(action = null): void {
    if (action !== null) {
      this.spinner.show();
    }

    declineSurvey(action).then(() => {});
  }

  isQuestionDismissible(): boolean {
    return this.currentQuestion.hasOwnProperty("Dismissible");
  }

  getDefaultCancelText(): string {
    if (this.isQuestionDismissible() && this.useWebView) {
      return this.currentQuestion.DefaultCancelText;
    }
    return null;
  }

  setProgressBar(): void {
    if (this.surveyObject !== null && this.currentQuestionIndex > -1) {
      const totalQuestions = this.surveyObject.Questions.length;
      const progress = ((this.currentQuestionIndex + 1) / totalQuestions * 100).toFixed(2);
      this.progressBarStyle = `width: ${progress}%`;
      this.ariaValueNow = progress.toString();
    }
  }

  setupDialog(): void {
    if (this.surveyObject.hasOwnProperty("NeedSupportOldApp")) {
      this.setupPositiveDialog();
    }
    else {
      this.setupNegativeDialog();
    }
  }

  skipEngagementCard(): boolean {
    return this.surveyObject.hasOwnProperty("SkipEngagementCardEnabled") && this.dayOffset >= this.scheduleStartDateForExperiencedUsers;
  }

  shouldHideOption(possibleAnswer: any): boolean {
    return this.shouldHideOptionForExperiencedUsers(possibleAnswer) ||
      this.shouldHideBreathOption(possibleAnswer);
  }

  shouldHideOptionForExperiencedUsers(possibleAnswer: any): boolean {
    return possibleAnswer.AnswerId === this.getUsedToTherapyAnswerId &&
      this.dayOffset >= this.scheduleStartDateForExperiencedUsers;
  }

  shouldHideBreathOption(possibleAnswer: any): boolean {
    return this.breathOptions.includes(possibleAnswer.AnswerId) &&
      !(this.environmentConfig.countryListSupportComfortQuestions.includes(this.countryId?.toUpperCase()) &&
        this.environmentConfig.languageListSupportRestrictedFeatures.includes(this.language?.toLowerCase()));
  }

  shouldHideQuestion(question: any): boolean {
    return question.QuestionId === this.baselineQuestionId && (this.baselineResponse !== null || this.dayOffset !== 3);
  }

  reachTheLastQuestion(): boolean {
    return this.currentQuestionIndex === this.surveyObject.Questions.length - 1;
  }

  handleSurveySkip(action = null): void {
    if (action !== null) {
      this.spinner.show();
    }

    if (isJavascriptInjectedIntoWebView()) {
      declineSurvey(action).then(() => {});
    }
    else {
      window.location.href = Constants.ExitToDashboardAction;
    }
  }

  resetSelection(): void {
    this.surveyObject.Questions[this.currentQuestionIndex].SelectedAnswer = null;
    this.selectedAnswer = null;
  }

  getSurveyDisplayEvent(): SurveyDisplayEvent {
    const surveyDisplayEvent = new SurveyDisplayEvent();
    surveyDisplayEvent.surveyId = this.surveyId;
    surveyDisplayEvent.sourceId = this.identity.generateSourceId();
    surveyDisplayEvent.patientId = this.patientId;
    surveyDisplayEvent.serial = this.serial;
    surveyDisplayEvent.dayOffset = this.dayOffset;
    surveyDisplayEvent.questionId = this.currentQuestion.QuestionId;
    surveyDisplayEvent.locale = this.language;

    return surveyDisplayEvent;
  }

  submitSurveyDisplayEvent(): Promise<any> {
    const surveyDisplayEvent = this.getSurveyDisplayEvent();
    return this.webInterface.submitSurveyDisplayEvent(
      surveyDisplayEvent,
      this.patientId,
      this.surveyAuthIdentifier,
      this.surveyId
    ).toPromise().catch(err => this.handlException(err));
  }

  handlException(err): void {
    console.log(`Error occurred when submitting a request. Error details: ${JSON.stringify(err)}`);
  }
}
