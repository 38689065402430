export enum AnswerSaveActions {
  Submit = 0,
  Continue = 1,
  SleepLibrary = 2
}

export enum MeasurementSystem {
  Imperial = 0,
  Metric = 1
}

export enum Units {
  Feet = 0,
  Inches = 1,
  Pounds = 2,
  Centimeters = 3,
  Kilograms = 4
}

export enum ResponseType {
  Enum = 0,
  Bmi = 1,
  FreeText = 2,
  MultiSelect = 3
}
