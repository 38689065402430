import { Headers, Http } from "@angular/http";
import { Injectable } from "@angular/core";

/**
 * Provider to help parse files and return the contents as usable data objects
 */
@Injectable()
export class FileManagerProvider {

  constructor(private http: Http) {
  }

  /**
   * Gets data with a promise which will return with the data when the task is complete
   * Uses generic type T to define schema interface to ensure returning json matches schema template.
   */
  public getJSONDataAsync(filePath: string): Promise<any> {
    const headers = new Headers({
      "Strict-Transport-Security": "max-age=31536000; includeSubDomains"
    });
    const httpOptions = { headers };
    return new Promise((resolve, reject) => {
      this.http.get(filePath, httpOptions)
        .subscribe(
          res => {
            if (!res.ok) {
              reject("Failed with status: " + res.status + "\nTrying to find file at " + filePath);
            }

            const jsonRes = res.json();
            resolve(jsonRes);
          },
          error => {
            reject(error);
          }
        );
    });
  }
}
