import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Bmi } from "src/model/Bmi";
import { MeasurementSystem, Units } from "src/utils/enums";

@Component({
  selector: "app-survey-bmi",
  templateUrl: "./survey-bmi.component.html",
  styleUrls: ["./survey-bmi.component.scss"]
})
export class SurveyBmiComponent {
  @Input() questionDetails: any;
  @Input() bmiInfo: Bmi;
  @Output() answerSelectedEvent = new EventEmitter<any>();

  feetValid = true;
  inchesValid = true;
  poundsValid = true;
  kilogramsValid = true;
  centimetersValid = true;
  bmiResult: number;
  measurementSystem = MeasurementSystem.Imperial;

  constructor() {
    console.log("Survey BMI component loaded.");
  }

  switchUnits(): void {
    this.clearForm();

    if (this.measurementSystem === MeasurementSystem.Imperial) {
      this.measurementSystem = MeasurementSystem.Metric;
    }
    else if (this.measurementSystem === MeasurementSystem.Metric) {
      this.measurementSystem = MeasurementSystem.Imperial;
    }
  }

  clearForm(): void {
    this.bmiResult = null;
    this.bmiInfo = new Bmi();
  }

  handleInputChange(inputValue: number, unit: Units): void {
    if (!this.validateInput(inputValue, unit)) {
      this.setValidity(false, unit);
      this.bmiResult = null;
      this.answerSelectedEvent.emit(null);
      return;
    }

    this.setValidity(true, unit);

    if (this.bmiInfo.isValid(this.measurementSystem)) {
      this.bmiResult = this.bmiInfo.calculateBmi(this.measurementSystem);
      this.answerSelectedEvent.emit(this.bmiInfo.convertToSurveyAnswer(this.questionDetails.Action ? this.questionDetails.Action : ""));
    }
  }

  validateInput(inputValue: number, unit: Units): boolean {
    switch (unit) {
      case Units.Feet:
        return Bmi.validateMeasurementValue(inputValue, Bmi.MinFeet, Bmi.MaxFeet);
      case Units.Inches:
        return Bmi.validateMeasurementValue(inputValue, Bmi.MinInches, Bmi.MaxInches);
      case Units.Pounds:
        return Bmi.validateMeasurementValue(inputValue, Bmi.MinPounds, Bmi.MaxPounds);
      case Units.Centimeters:
        return Bmi.validateMeasurementValue(inputValue, Bmi.MinCentimeters, Bmi.MaxCentimeters);
      case Units.Kilograms:
        return Bmi.validateMeasurementValue(inputValue, Bmi.MinKilograms, Bmi.MaxKilograms);
      default:
        return false;
    }
  }

  setValidity(valid: boolean, unit: Units): void {
    switch (unit) {
      case Units.Feet:
        this.feetValid = valid;
        break;
      case Units.Inches:
        this.inchesValid = valid;
        break;
      case Units.Pounds:
        this.poundsValid = valid;
        break;
      case Units.Centimeters:
        this.centimetersValid = valid;
        break;
      case Units.Kilograms:
        this.kilogramsValid = valid;
        break;
    }
  }
}
