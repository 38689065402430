export class SurveyDisplayEvent {
  sourceId: string;
  surveyId: string;
  patientId: string;
  serial: string;
  dayOffset: number;
  questionId: string;
  locale: string;

  public constructor(public dateTime: string = new Date().toISOString()) {
  }
}
