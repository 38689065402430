import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-survey-engagement-card',
  templateUrl: './survey-engagement-card.component.html',
  styleUrls: ['./survey-engagement-card.component.scss']
})
export class SurveyEngagementCardComponent implements OnInit {
  @Input() engagementCardObj: any;
  constructor() {
  }

  ngOnInit(): void {
  }
}
