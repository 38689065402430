import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { SurveyDisplayComponent } from "./survey-display/survey-display.component";
import { OnboardingComponent } from "./onboarding/onboarding.component";

const routes: Routes = [
  { path: "survey/:surveyId/:surveyDisplayId/:dayOffset/:surveyAuthIdentifier", component: SurveyDisplayComponent },
  { path: "survey/:surveyId/:surveyDisplayId/:dayOffset/:surveyAuthIdentifier/:baselineResponse", component: SurveyDisplayComponent },
  { path: "onboarding/:surveyId/:surveyDisplayId/:dayOffset/:surveyAuthIdentifier", component: OnboardingComponent },
  { path: "**", component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
