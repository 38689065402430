import { SurveyQuestion } from "./SurveyQuestion";


export class SurveyResponse {
    surveyId: string;
    sourceId: string;
    questions: SurveyQuestion[] = [];

    public constructor() {
    }
 }
