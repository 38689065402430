import { EcnIdentifier } from "./EcnIdentifier";
import { PatientIdIdentifier } from "./PatientIdIdentifier";
import { SerialIdentifier } from "./SerialIdentifier";

export class UserIdentity {
    public serialIdentifier: SerialIdentifier;
    public patientIdIdentifier: PatientIdIdentifier;
    public ecnIdentifier: EcnIdentifier;

    public constructor(
        serialNumber: string,
        patientId: string,
        ecn?: string,
    ) {
        this.patientIdIdentifier = new PatientIdIdentifier(patientId);

        if (serialNumber){
          this.serialIdentifier = new SerialIdentifier(serialNumber);
        }

        if (ecn) {
            this.ecnIdentifier = new EcnIdentifier(ecn);
        }
    }

    /**
     * Generates the source ID field for the response in the
     * following order:
     *
     * 1. ECN (preferred, but likely not present in myAir)
     * 2. Serial number
     * 3. myAir Patient ID
     */
    generateSourceId(): string {
        if (this.ecnIdentifier)
        {
            return this.ecnIdentifier.generateSourceId();
        }

        if (this.serialIdentifier) {
            return this.serialIdentifier.generateSourceId();
        }

        return this.patientIdIdentifier.generateSourceId();
    }

    toString(): string {
        let identity = `Patient ID: ${this.patientIdIdentifier.patientId}`;

        if (this.serialIdentifier) {
          identity += ` Serial: ${this.serialIdentifier.serialNumber}\`;`
        }

        if (this.ecnIdentifier) {
            identity += ` ECN: ${this.ecnIdentifier.ecn}`;
        }

        return identity;
    }
 }
