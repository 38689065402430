import {EnvService} from "./env.service";

export class AppSettings {
  public static baseUri = "";
  public static buildTime = "";

  public static init(data: DOMStringMap, environmentSettings: EnvService): void {
    this.baseUri = (data.baseUri || "").replace(/\/+$/, "");
    this.buildTime = environmentSettings.BuildTime;

  }
}
