<ngx-spinner></ngx-spinner>

<div id="header" class="o-mobile-page__header" *ngIf="showHeader">
  <div class="c-table">
    <div class="c-table__cell valign-bottom">
      <h2 health="health-profile" class="c-heading-mobile">{{ pageHeaderTextStringId | translate}}</h2>
    </div>
  </div>
</div>

<div class="o-mobile-page">
  <div class="o-mobile-page__body has-footer" [ngClass]="showHeader ? 'has-header' : ''">

    <div class="c-survey">

      <div class="c-survey__row" *ngIf="showEngagementCard">
        <app-survey-engagement-card class="c-survey__top valign-middle" [engagementCardObj]="engagementCardObj"></app-survey-engagement-card>
      </div>

      <div class="c-survey__row" *ngIf="showPositiveDialog">
        <app-survey-dialog class="c-survey__top valign-middle" [dialogObj]="dialogObj"></app-survey-dialog>
      </div>

      <div class="c-survey__row" *ngIf="showNegativeDialog">
        <app-survey-dialog class="c-survey__top valign-middle" [dialogObj]="dialogObj"></app-survey-dialog>
      </div>

      <div class="c-survey__row" *ngIf="currentQuestion?.Template === 'Image'">
        <app-survey-image class="c-survey__top" [questionDetails]="currentQuestion" (answerSelectedEvent)="answerSelected($event)"></app-survey-image>
      </div>

      <div class="c-survey__row" *ngIf="currentQuestion?.Template !== 'Image'">
        <app-survey-button class="c-survey__top" *ngIf="currentQuestion?.Template === 'Button'" [questionDetails]="currentQuestion" (answerSelectedEvent)="answerSelected($event)"></app-survey-button>
        <app-survey-button-multi class="c-survey__top" *ngIf="currentQuestion?.Template === 'ButtonMulti'" [questionDetails]="currentQuestion" (answerSelectedEvent)="answerSelected($event)"></app-survey-button-multi>
        <app-survey-bmi class="c-survey__top" *ngIf="currentQuestion?.Template === 'BMI'" [bmiInfo]="patientBmi" [questionDetails]="currentQuestion" (answerSelectedEvent)="answerSelected($event)"></app-survey-bmi>
      </div>

      <div class="c-survey__row">
        <div class="c-survey__bottom">
          <div class="c-survey__help">
            <span [innerHTML]="selectedText | translate | linky"></span>
          </div>
          <a class="c-survey__help" *ngIf="selectedAnswer?.AnswerReference" href="{{selectedAnswer?.AnswerReference?.Url}}" target="_blank">{{ selectedAnswer?.AnswerReference?.Text | translate }}</a>
        </div>
      </div>

    </div>

  </div>
</div>

<div class="o-mobile-page__footer">
  <div class="c-table">
    <div class="c-table__cell valign-bottom">
      <p class="c-survey__error u-margin-bottom-small" *ngIf="!valid">{{ validationTextStringId | translate }}</p>
      <div><button id="continueButton" type="button" (click)="this.continueSelected()" class="c-btn c-btn--primary">{{ continueText | translate }}</button></div>
      <div class="u-padding-top" *ngIf="cancelText"><button id="cancelButton" type="button" (click)="this.cancelSelected()" class="c-btn">{{ cancelText | translate }}</button></div>
    </div>
  </div>
</div>
