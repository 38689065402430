import { UserIdentity } from "./UserIdentity";

export class SurveySource {
    private origin = "myair";
    private deviceSerial: string;
    private patientId: string;
    private patientEcn: string;

    public constructor(
        userIdentiy: UserIdentity
    ) {
        this.patientId = userIdentiy.patientIdIdentifier.patientId;

        if (userIdentiy.serialIdentifier) {
          this.deviceSerial = userIdentiy.serialIdentifier.serialNumber;
        }

        if (userIdentiy.ecnIdentifier) {
            this.patientEcn = userIdentiy.ecnIdentifier.ecn;
        }
    }
 }
