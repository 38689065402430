import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-survey-image",
  templateUrl: "./survey-image.component.html",
  styleUrls: ["./survey-image.component.scss"]
})
export class SurveyImageComponent implements OnInit {
  @Input() questionDetails: any;
  @Output() answerSelectedEvent = new EventEmitter<any>();

  imageSources: string[] = [];
  selectedImageIndex = -1;
  answerText: string;
  sleepinessSelectedImageFile: string;
  selectYourSleepinessStringId = "select-your-sleepiness";


  constructor() {
    console.log("Survey image component loaded.");
  }

  ngOnInit(): void {
    for (const answer of this.questionDetails.PossibleAnswers) {
      this.imageSources.push(answer.Images.Unselected);
    }

    if (this.questionDetails.hasOwnProperty("SelectedAnswer")) {
      this.highlightSelectedImage(this.questionDetails.SelectedAnswer.Index);
    }

    this.sleepinessSelectedImageFile = this.questionDetails.hasOwnProperty("SelectedAnswer") ? this.imageSources[this.questionDetails.SelectedAnswer.Index] : "sleepy_default.svg";
  }

  imageSelected(answer: any, index: number): void {
    answer.Index = index;
    this.answerSelectedEvent.emit(answer);
    this.highlightSelectedImage(index);
    this.answerText = answer.AnswerText ? answer.AnswerText : null;
  }

  highlightSelectedImage(index: number): void {
    if (this.selectedImageIndex >= 0) {
      // reset original image only if one is selected already
      this.imageSources[this.selectedImageIndex] = this.questionDetails.PossibleAnswers[this.selectedImageIndex].Images.Unselected;
    }
    this.imageSources[index] = this.questionDetails.PossibleAnswers[index].Images.Selected;
    this.sleepinessSelectedImageFile =  this.imageSources[index];
    this.selectedImageIndex = index;
    this.answerText = this.questionDetails.PossibleAnswers[this.selectedImageIndex].AnswerText;
  }
}
