/**
 * Base class that we will use to store information
 * about how to identify the user.
 */
export abstract class UserIdentifier {
    /**
     * Key that is used to identify which type
     * of ID is being used.
     */
    abstract IndentifierKey: string;

    public constructor(
        public identifier: string
    ) {
    }

    /**
     * Generates the source ID field of a survey
     * response based on the type of ID we are using.
     */
    public generateSourceId(): string {
        return `${this.IndentifierKey}#${this.identifier}`;
    }
 }
