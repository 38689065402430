import { Component, OnInit } from "@angular/core";
import {SurveyBaseComponent} from "../survey-base/survey-base.component";
import {UserIdentity} from "../../model/UserIdentity";
import {Constants} from "../../utils/constants";

@Component({
  selector: "app-onboarding",
  templateUrl: "./onboarding.component.html",
  styleUrls: ["./onboarding.component.scss"]
})
export class OnboardingComponent extends SurveyBaseComponent implements OnInit {
  pageHeaderTextStringId = "my_health";
  firstDayOnTherapyQuestionId = "first-day-on-therapy";
  bmiQuestionId = "bmi";
  showFirstDayOnTherapyQuestion = false;

  // The "how were you set up on therapy" options for EU market only
  euTherapySetupOptions = [
    "at-home-with-digital-written-instructions",
    "individual-setup-on-virtual-meeting-platform",
    "group-setup-on-virtual-meeting-platform",
    "group-setup-at-clinician-office",
    "individual-setup-at-clinician-office"
  ];

  // The "how were you set up on therapy" options for ROW market only
  rowTherapySetupOptions = [
    "went-to-clinician",
    "setup-class",
    "equipment-mailed"
  ];

  clinicianInMyHomeAnswerId = "clinician-came-to-home";

  readRouteParams(): void {
    this.surveyId = this.route.snapshot.paramMap.get("surveyId");
    this.dayOffset = +this.route.snapshot.paramMap.get("dayOffset");
    this.surveyDisplayId = this.route.snapshot.paramMap.get("surveyDisplayId");
    this.surveyAuthIdentifier = this.route.snapshot.paramMap.get("surveyAuthIdentifier");
  }

  readIdentity(): void {
    this.patientId = this.readFromQueryString("patientId");
    this.language = this.readFromQueryString("language", false);
    this.countryId = this.readFromQueryString("countryId", false);
    this.identity = new UserIdentity(null, this.patientId, null);
  }

  readSetFirstDayOnTherapy(): void {
    const showFirstDay = this.readFromQueryString("askIfFirstDayOnTherapy", false);

    if (showFirstDay != null) {
      this.showFirstDayOnTherapyQuestion = showFirstDay.toLowerCase() === "true";
    }
  }

  continueSelected(): void {
    if (this.surveyObject.Questions[this.currentQuestionIndex].hasOwnProperty("SelectedAnswer")){
      this.selectedAnswer = this.surveyObject.Questions[this.currentQuestionIndex].SelectedAnswer;
    }

    if (!this.valid) {
      return;
    }
    this.handleQuestionContinueAction();
    this.selectedAnswer = null;
  }

  cancelSelected(): void {
    this.previousQuestion();
  }

  skipSelected(): void {
    // only trigger the survey decline request if the user selects skip on the last question
    if (this.reachTheLastQuestion()) {
      this.handleSurveySkip(Constants.ExitToDashboardAction);
      return;
    }

    this.nextQuestion();
    this.selectedAnswer = null;
  }

  getDefaultCancelText(): string {
    return this.currentQuestion.DefaultCancelText;
  }

  updatePageText(): void {
    this.selectedText = this.getAnswerSelectedText(this.selectedAnswer);
    this.continueText = this.currentQuestion.DefaultContinueText;
    this.cancelText = this.getDefaultCancelText();
  }

  setupSurveyQuestions(): void {
    this.readSetFirstDayOnTherapy();
    super.setupSurveyQuestions();

    if (this.surveyObject.hasOwnProperty("Questions")) {
      this.surveyObject.Questions[0].DefaultCancelText = "";
      this.cancelText = this.getDefaultCancelText();
      this.surveyObject.Questions[this.surveyObject.Questions.length - 1].DefaultContinueText = "done";
      this.surveyObject.Questions[this.surveyObject.Questions.length - 1].Action = "submit";

      this.setProgressBar();
    }
  }

  shouldHideQuestion(question: any): boolean {
    return this.shouldHideBmi(question) || this.shouldHideFirstDayOnTherapy(question);
  }

  shouldHideBmi(question: any): boolean {
    return question.QuestionId === this.bmiQuestionId &&
      !(this.environmentConfig.countryListSupportBmi.includes(this.countryId?.toUpperCase()) &&
        this.environmentConfig.languageListSupportRestrictedFeatures.includes(this.language?.toLowerCase()));
  }

  shouldHideFirstDayOnTherapy(question: any): boolean {
    return question.QuestionId === this.firstDayOnTherapyQuestionId && !this.showFirstDayOnTherapyQuestion;
  }

  shouldHideOption(possibleAnswer: any): boolean {
    return this.shouldHideEuTherapySetupOption(possibleAnswer) ||
      this.shouldHideRowTherapySetupOption(possibleAnswer) ||
      this.shouldHideClinicianAtHomeOption(possibleAnswer);
  }

  shouldHideRowTherapySetupOption(possibleAnswer: any): boolean {
    return this.rowTherapySetupOptions.includes(possibleAnswer.AnswerId) &&
      this.environmentConfig.countryListSupportEuTherapySetupOptions.includes(this.countryId);
  }

  shouldHideEuTherapySetupOption(possibleAnswer: any): boolean {
    return this.euTherapySetupOptions.includes(possibleAnswer.AnswerId) &&
      !this.environmentConfig.countryListSupportEuTherapySetupOptions.includes(this.countryId);
  }

  shouldHideClinicianAtHomeOption(possibleAnswer: any): boolean {
    return possibleAnswer.AnswerId === this.clinicianInMyHomeAnswerId &&
      this.environmentConfig.countryListDisableClinicianAtHomeOption.includes(this.countryId);
  }
}
