<div class="c-survey__section survey-question">
    <h2 class="c-survey__question">{{ questionDetails.QuestionText | translate }}</h2>
    <div>
      <button type="button" (click)="this.switchUnits()" unit="change-units" class="c-btn c-btn--text">{{ 'change-units' | translate }}</button>
    </div>

    <div>
        <label class="c-form-field__label">{{ 'height' | translate }}</label>
        <div *ngIf="measurementSystem == 0" class="c-form-field u-margin-bottom-large">
          <div class="o-layout o-layout--small o-layout--middle">
            <div class="o-layout__item u-1/4">
              <div class="c-form-field__input">
                <input [(ngModel)]="bmiInfo.feet" id="feet" class="c-input c-input--number u-text-align-right" type="number" min="{{bmiInfo.minFeet}}" max="{{bmiInfo.maxFeet}}" (ngModelChange)="handleInputChange($event, 0)">
              </div>
            </div>
            <div class="o-layout__item u-1/4 u-text-align-left">
              <label class="c-form-field__hint" for="feet">{{ 'feet' | translate }}</label>
            </div>
          </div>
          <p class="c-survey__error" *ngIf="!feetValid">{{ 'feet-validation' | translate }}</p>

          <div class="o-layout o-layout--small o-layout--middle">
            <div class="o-layout__item u-1/4">
              <div class="c-form-field__input">
                <input [(ngModel)]="bmiInfo.inches" id="inches" class="c-input c-input--number u-text-align-right" type="number" min="{{bmiInfo.minInches}}" max="{{bmiInfo.maxInches}}" (ngModelChange)="handleInputChange($event, 1)">
              </div>
            </div>
            <div class="o-layout__item u-1/4 u-text-align-left">
              <label class="c-form-field__hint" for="inches">{{ 'inches' | translate }}</label>
            </div>
          </div>
          <p class="c-survey__error" *ngIf="!inchesValid">{{ 'inches-validation' | translate }}</p>
        </div>

        <div *ngIf="measurementSystem == 1" class="c-form-field u-margin-bottom-large">
          <div class="o-layout o-layout--small o-layout--middle">
            <div class="o-layout__item u-1/4">
              <div class="c-form-field__input">
                <input [(ngModel)]="bmiInfo.centimeters" id="cm" class="c-input c-input--number u-text-align-right" type="number" min="{{bmiInfo.minCentimeters}}" max="{{bmiInfo.maxCentimeters}}" (ngModelChange)="handleInputChange($event, 3)">
              </div>
            </div>
            <div class="o-layout__item u-1/4 u-text-align-left">
              <label class="c-form-field__hint" for="cm">{{ 'centimeters' | translate }}</label>
            </div>
          </div>
          <p class="c-survey__error" *ngIf="!centimetersValid">{{ 'centimeter-validation' | translate }}</p>
        </div>

        <label class="c-form-field__label">{{ 'weight' | translate }}</label>
        <div *ngIf="measurementSystem == 0" class="c-form-field">
          <div class="o-layout o-layout--small o-layout--middle">
            <div class="o-layout__item u-1/4">
              <div class="c-form-field__input">
                <input [(ngModel)]="bmiInfo.pounds" id="pounds" class="c-input c-input--number u-text-align-right" type="number" min="{{bmiInfo.minPounds}}" max="{{bmiInfo.maxPounds}}" (ngModelChange)="handleInputChange($event, 2)">
              </div>
            </div>
            <div class="o-layout__item u-1/4 u-text-align-left">
              <label class="c-form-field__hint" for="pounds">{{ 'pounds' | translate }}</label>
            </div>
          </div>
          <p class="c-survey__error" *ngIf="!poundsValid">{{ 'pounds-validation' | translate }}</p>
        </div>

        <div *ngIf="measurementSystem == 1" class="c-form-field">
          <div class="o-layout o-layout--small o-layout--middle">
            <div class="o-layout__item u-1/4">
              <div class="c-form-field__input">
                <input [(ngModel)]="bmiInfo.kilograms" id="kilos" class="c-input c-input--number" type="number" min="{{bmiInfo.minKilograms}}" max="{{bmiInfo.maxKilograms}}" (ngModelChange)="handleInputChange($event, 4)">
              </div>
            </div>
            <div class="o-layout__item u-1/4 u-text-align-left">
              <label class="c-form-field__hint" for="kilos">{{ 'kilograms' | translate }}</label>
            </div>
          </div>
          <p class="c-survey__error" *ngIf="!kilogramsValid">{{ 'kilogram-validation' | translate }}</p>
        </div>
    </div>
    <div *ngIf="bmiResult" style="padding:48px 0;">
      <h5>{{'your-bmi-is' | translate: { bmiValue: bmiResult} }}</h5>
    </div>
</div>
