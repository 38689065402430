import { SurveyAnswer } from "./SurveyAnswer";
import { SurveySource } from "./SurveySource";

export class SurveyQuestion {
    dayOffset: number;
    source: SurveySource;
    questionId: string;
    answers: SurveyAnswer[] = [];

    public constructor(public dateTime: string = new Date().toISOString()) {
    }
 }
