import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { ResponseType } from "src/utils/enums";

@Component({
  selector: "app-survey-button",
  templateUrl: "./survey-button.component.html",
  styleUrls: ["./survey-button.component.scss"]
})

export class SurveyButtonComponent implements OnChanges {
  @Input() questionDetails: any;
  @Output() answerSelectedEvent = new EventEmitter<any>();

  activeIndex: number;

  constructor() {
    console.log("Survey button component loaded.");
  }

  answerSelected(answer: any, index: number): void {
    answer.Index = index;
    if (answer.FreeText && answer.Description?.trim() !== "") {
      answer.ResponseType = ResponseType.FreeText;
      answer.Description = answer.Description?.trim();
    }

    this.answerSelectedEvent.emit(answer);
  }

  ngOnChanges(): void {
    if (this.questionDetails.hasOwnProperty("SelectedAnswer")) {
      this.activeIndex = this.questionDetails.SelectedAnswer.Index;
    }
    else {
      this.activeIndex = -1;
    }
  }
}
