/**
 * Helper class to define app constants
 */
export class Constants {
    static ExitToDashboardAction = "#close-activity";
    static ExitToSleepLibraryAction = "#start_activity_coaching_library";
    static ExitToCoachingPTAMachineSetupAction = "#start_coaching_pta_machine_setup"
    static ExitToCoachingPTAMaskSetupAction = "#start_coaching_pta_mask_setup"
    static ExitToCoachingPTATestDriveAction = "#start_coaching_pta_test_drive"
    static ExitToCoachingLibraryEquipmentAction = "#start_coaching_library_equipment_video"
    static ExitToCoachingLibraryMaskAction = "#start_coaching_library_mask_video"
    static ExitToCoachingGeneralLearningAction = "#start_coaching_library_general_learn_video"
}
