import {Component, OnInit} from "@angular/core";
import {SurveyBaseComponent} from "../survey-base/survey-base.component";

@Component({
  selector: "app-survey-display",
  templateUrl: "./survey-display.component.html",
  styleUrls: ["./survey-display.component.scss"]
})

export class SurveyDisplayComponent extends SurveyBaseComponent implements OnInit {

}
