import { HttpClient } from "@angular/common/http";
import { AppSettings } from "../app.settings";

import { TranslateLoader } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Injectable, OnDestroy } from "@angular/core";

@Injectable()
export class CustomTranslateLoader implements TranslateLoader, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private http: HttpClient) {
  }

  public getTranslation(lang: string): Observable<any> {
    return new Observable<any>(observer => {
        // for language json requests, ensure cache buster parameter is set to a unique ID for the build.
        this.http.get(AppSettings.baseUri + "/assets/i18n/" + lang + ".json" + "?cb=" + AppSettings.buildTime).pipe(
          takeUntil(this.ngUnsubscribe))
          .subscribe(data => {
            observer.next(data);
            observer.complete();
          });
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
