import { SurveyAnswer } from "./SurveyAnswer";

export class SurveyStringAnswer extends SurveyAnswer {
    private static MaxStringLength = 100;

    public valueString: string;

    public constructor(
        valueString: string
    ) {
        super();
        this.valueString = valueString.substring(0, SurveyStringAnswer.MaxStringLength);
    }
 }
