<ngx-spinner></ngx-spinner>

<div id="header" class="o-mobile-page__header">
  <div class="c-table">
    <div class="c-table__cell valign-bottom u-1/6">&nbsp;</div>
    <div class="c-table__cell valign-bottom u-text-align-center u-4/6">
      <h2 class="c-heading-mobile">{{ pageHeaderTextStringId | translate }}</h2>
    </div>
    <div class="c-table__cell valign-bottom u-text-align-right  u-1/6">
      <button id="skipButton" buttontype="skipButton" type="button" (click)="this.skipSelected()" class="c-mobile-nav-link is-skip-btn">{{ skipTextStringId | translate }}</button>
    </div>
  </div>
</div>

<div class="o-mobile-page">
  <div class="o-mobile-page__body is-onboarding has-header">

    <div class="c-survey">
      <div class="c-survey__row" *ngIf="currentQuestion?.Template === 'Image'">
        <app-survey-image class="c-survey__top" [questionDetails]="currentQuestion" (answerSelectedEvent)="answerSelected($event)"></app-survey-image>
      </div>
      <div class="c-survey__row" *ngIf="currentQuestion?.Template !== 'Image'">
        <app-survey-button class="c-survey__top" *ngIf="currentQuestion?.Template === 'Button'" [questionDetails]="currentQuestion"  (answerSelectedEvent)="answerSelected($event)"></app-survey-button>
        <app-survey-button-multi class="c-survey__top" *ngIf="currentQuestion?.Template === 'ButtonMulti'" [questionDetails]="currentQuestion" (answerSelectedEvent)="answerSelected($event)"></app-survey-button-multi>
        <app-survey-bmi class="c-survey__top" *ngIf="currentQuestion?.Template == 'BMI'" [bmiInfo]="patientBmi" [questionDetails]="currentQuestion" (answerSelectedEvent)="answerSelected($event)"></app-survey-bmi>
      </div>
      <div class="c-survey__row">
        <div class="c-survey__bottom">
          <p class="c-survey__help">
            <span [innerHTML]="selectedText | translate | linky"></span>
          </p>
          <a class="c-survey__help" *ngIf="selectedAnswer?.AnswerReference" href="{{selectedAnswer?.AnswerReference?.Url}}" target="_blank">{{ selectedAnswer?.AnswerReference?.Text | translate }}</a>
        </div>
      </div>
    </div>

  </div>
</div>

<div class="o-mobile-page__footer is-onboarding">
  <div class="c-table">
    <div class="c-table__cell valign-bottom">

      <p class="c-survey__error u-margin-bottom-none" error="error-message" *ngIf="!valid">{{ validationTextStringId | translate }}</p>
      <nav class="c-pagination c-pagination--steps no-chevrons" aria-label="Survey page navigation">
        <ul class="c-pagination__list">
          <li class="c-pagination__list-item">
            <button id="cancelButton" type="button" buttontype="cancelButton" (click)="this.cancelSelected()" class="c-pagination__link prev-page"  *ngIf="cancelText">{{ cancelText | translate }}</button>
          </li>
          <li class="c-pagination__list-item">&nbsp;</li>
          <li class="c-pagination__list-item">
            <button id="continueButton" type="button" buttontype="continueButton" (click)="this.continueSelected()" class="c-pagination__link next-page">{{ continueText | translate }}</button>
          </li>
        </ul>
      </nav>

    </div>
  </div>

  <div class="c-progress c-progress--gradient">
    <div id="progressBar" class="c-progress__bar" style="{{progressBarStyle}}" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
  </div>
</div>

