import { UserIdentifier } from "./UserIdentifier";

/**
 * Class which uses device serial number as the user identifier
 */
export class SerialIdentifier extends UserIdentifier {
    IndentifierKey = "DEVICE_SERIAL";

    public constructor(
        public serialNumber: string
    ) {
        super(serialNumber);
    }
 }
