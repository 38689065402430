import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";

import { SurveyResponseWrapper } from "src/model/SurveyResponseWrapper";
import { EnvService } from "src/app/env.service";
import {SurveyDecline} from "../../model/SurveyDecline";
import {SurveyDisplayEvent} from "../../model/SurveyDisplayEvent";

@Injectable()
export class WebSurveyProvider {

  constructor(
    private http: HttpClient,
    private environmentConfig: EnvService) { }

  /** Save a new survey response to the server.
   *  @param surveyResponse - The response containing the user's answers.
   */
  saveAnswers(surveyResponse: SurveyResponseWrapper, patientId: string, surveyIdentifier: string, surveyId: string): Observable<SurveyResponseWrapper> {
    const endpointURL = `${this.environmentConfig.SurveyApiUrl}/survey/${surveyResponse.surveyResponse.surveyId}/response`;

    const httpOptions = {
      headers: this.generateRequestHeader(patientId, surveyIdentifier, surveyId)
    };

    return this.http.post<SurveyResponseWrapper>(endpointURL, surveyResponse, httpOptions).pipe(
      tap(() => {
        console.log(`Posted new response for survey ID with id="${surveyResponse.surveyResponse.surveyId}" and source ID "${surveyResponse.surveyResponse.sourceId}"`);
      }),
      catchError(err => {
        console.error(`Save answers failed. Error details: ${JSON.stringify(err)}`);
        return throwError(err);
      })
    );
  }

  submitSurveyDecline(surveyDecline: SurveyDecline, patientId: string, surveyIdentifier: string, surveyId: string): Observable<SurveyDecline> {
    const endpointURL = `${this.environmentConfig.SurveyApiUrl}/survey/${surveyId}/response/decline`;

    const httpOptions = {
      headers: this.generateRequestHeader(patientId, surveyIdentifier, surveyId)
    };

    return this.http.post<SurveyDecline>(endpointURL, surveyDecline, httpOptions).pipe(
      tap(() => {
        console.log(`Submitted survey decline for survey ID with id="${surveyDecline.surveyId}" and source ID "${surveyDecline.sourceId}"`);
      }),
      catchError(err => {
        console.error(`Submit survey decline failed. Error details: ${JSON.stringify(err)}`);
        return throwError(err);
      })
    );
  }

  submitSurveyDisplayEvent(surveyDisplayEvent: SurveyDisplayEvent, patientId: string, surveyIdentifier: string, surveyId: string): Observable<SurveyDisplayEvent> {
    const endpointURL = `${this.environmentConfig.SurveyApiUrl}/event`;

    const httpOptions = {
      headers: this.generateRequestHeader(patientId, surveyIdentifier, surveyId)
    };

    return this.http.post<SurveyDisplayEvent>(endpointURL, surveyDisplayEvent, httpOptions).pipe(
      tap(() => {
        console.log(`Submitted survey display event for survey ID with id="${surveyDisplayEvent.surveyId}" and source ID "${surveyDisplayEvent.sourceId}"`);
      }),
      catchError(err => {
        console.error(`Submit survey display event failed. Error details: ${JSON.stringify(err)}`);
        return throwError(err);
      })
    );
  }

  generateAuthToken(patientId: string, surveyIdentifier: string, surveyId: string): string {
    const encodedAuthToken = btoa(`${patientId}:${surveyIdentifier}:${surveyId}`);

    return `Bearer ${encodedAuthToken}`;
  }

  generateRequestHeader(patientId: string, surveyIdentifier: string, surveyId: string): HttpHeaders {
    const headersObject = new HttpHeaders()
      .set("Authorization", this.generateAuthToken(patientId, surveyIdentifier, surveyId))
      .set("Content-Type", "application/json");

    return headersObject;
  }
}
