import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { ResponseType } from "src/utils/enums";

@Component({
  selector: "app-survey-button-multi",
  templateUrl: "./survey-button-multi.component.html",
  styleUrls: ["./survey-button-multi.component.scss"]
})
export class SurveyButtonMultiComponent implements OnChanges {
  @Input() questionDetails: any;
  @Output() answerSelectedEvent = new EventEmitter<any>();

  selectedAnswerIndexes: number[] = [];

  constructor() {
    console.log("Survey button multi component loaded.");
  }

  ngOnInit(): void {
    let indexes = [];
    if (this.questionDetails.hasOwnProperty("SelectedAnswer")) {
      let answers = this.questionDetails.SelectedAnswer?.Answers;

      if (answers) {
        answers.forEach(function (value) {
          indexes.push(value.Index);
        });
      }

      this.selectedAnswerIndexes = indexes;
    }
  }

  answerSelected(answerIndex: number): void {
    const isButtonSelection = Number.isInteger(answerIndex); // free text entries also trigger this function.
    const index = this.selectedAnswerIndexes.indexOf(answerIndex);

    if (isButtonSelection && index >= 0) {
      // answer un-selected. Remove from list
      this.selectedAnswerIndexes.splice(index, 1);
    }
    else if (isButtonSelection) {
      // answer selected. Add to list
      this.selectedAnswerIndexes.push(answerIndex);
    }

    this.answerSelectedEvent.emit(this.convertToSurveyAnswer());
  }

  ngOnChanges(): void {
    // question has changed. Reset active selection
    this.selectedAnswerIndexes = [];
  }

  convertToSurveyAnswer(): any {
    if (this.selectedAnswerIndexes.length === 0) {
      return null;
    }

    const selectedAnswerIds = [];

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.selectedAnswerIndexes.length; i++) {
      const answer = this.questionDetails.PossibleAnswers[this.selectedAnswerIndexes[i]];
      const answerId = answer.AnswerId;

      if (answer.FreeText && answer.Description?.trim() === "") {
        // free text missing. Return to display control to display validation message
        return answer;
      }
      else if (answer.FreeText) {
        selectedAnswerIds.push({
          Index: this.selectedAnswerIndexes[i],
          AnswerId: answerId,
          Description: answer.Description?.trim(),
          ResponseType: ResponseType.FreeText
        });
      }
      else {
        selectedAnswerIds.push({
          Index: this.selectedAnswerIndexes[i],
          AnswerId: answerId
        });
      }
    }

    const surveyAnswer: any = {
        Answers: selectedAnswerIds,
        Action: this.questionDetails.Action,
        ResponseType: ResponseType.MultiSelect
    };

    return surveyAnswer;
  }
}
