<!-- Question -->
<div class="c-survey__section survey-question">
  <h2 class="c-survey__question is-multi-question" *ngFor="let text of questionDetails.QuestionText">{{ text | translate }}</h2>
</div>

<!-- Button style answer choices list -->
<div class="c-survey__section survey-choices">
  <span *ngFor="let answer of questionDetails.PossibleAnswers; let i = index">
    <button type="button" [attr.answerId]="answer.AnswerId" class="c-survey__answer" [ngClass]="selectedAnswerIndexes.includes(i) ? 'is-active' : '' " (click)="this.answerSelected(i)">{{answer.AnswerText | translate}}</button>
    <span *ngIf="answer.FreeText && selectedAnswerIndexes.includes(i)" class="c-form-field  u-text-align-left">
      <label class="c-form-field__label">{{'describe' | translate}}</label>
      <div class="c-form-field__input">
        <input maxlength="100" type="text" class="c-input c-input--text" [(ngModel)]="answer.Description" (ngModelChange)="this.answerSelected(answer)">
      </div>
      <div class="c-form-field__hint">
        {{ 'enter-description' | translate }}
      </div>
    </span>
  </span>
</div>
