export class EnvService {

  // The values that are defined here are the default values that can
  // be overridden by env.js

  public production = false;

  public SurveyApiUrl = "https://survey-api.sleep-concierge-dev.dht.live";

  public DefaultLocale = "en-us";

  public BuildTime = "0";

  public SupportedLocales = [
    "en",
    "ms",
    "en-us",
    "en-gb",
    "fr",
    "fr-ca",
    "de",
    "ar",
    "cz",
    "da",
    "el",
    "es",
    "es-latam",
    "fi",
    "it",
    "jp",
    "ko",
    "nl",
    "no",
    "po",
    "pt",
    "pt-br",
    "sv",
    "th",
    "tr",
    "zh-hant"
  ];

  public defaultLogLevel = 2;
  public stgLogLevel = 7;
  public qaLogLevel = 1;
  public prodLogLevel = 7;
  public sbxLogLevel = 1;
  public demoLoggerLevel = 7;
  public countryListSupportComfortQuestions = ["US"];
  public countryListSupportBmi = ["US"];
  public languageListSupportRestrictedFeatures = ["en-us"];
  public countryListSupportEuTherapySetupOptions = ["AT", "BE", "CH", "CZ", "DE", "DK", "ES", "FI", "FR", "GB", "IE", "IT", "LU", "NL", "NO", "PL", "PT", "SE"];
  public countryListDisableClinicianAtHomeOption = ["DK", "FI", "GB", "NO", "SE"];

  constructor() {
  }

}
