import { MeasurementSystem, ResponseType } from "src/utils/enums";

export class Bmi {
    static readonly MinFeet = 1;
    static readonly MaxFeet = 9;
    static readonly MinInches = 0;
    static readonly MaxInches = 11;
    static readonly MinPounds = 1;
    static readonly MaxPounds = 1000;
    static readonly MinCentimeters = 1;
    static readonly MaxCentimeters = 302;
    static readonly MinKilograms = 1;
    static readonly MaxKilograms = 454;
    static readonly WeightQuestionId = "bmi-weight";
    static readonly HeightQuestionId = "bmi-height";
    static readonly BmiResultQuestionId = "bmi-result";

    feet: number;
    inches: number;
    pounds: number;
    centimeters: number;
    kilograms: number;

    public static validateMeasurementValue(measurementValue, minValue, maxValue): boolean {
        return Number.isInteger(measurementValue) && measurementValue >= minValue && measurementValue <= maxValue;
    }

    public static roundBmiResult(bmiResult: number): number {
        return Math.round((bmiResult + Number.EPSILON) * 100) / 100;
    }

    public static calculateBmi(centimeters: number, kilograms: number): number {
        const heightMeters = centimeters / 100;
        return Bmi.roundBmiResult(kilograms / (heightMeters * heightMeters));
    }

    public constructor() {
    }

    public calculateBmi(unitType: MeasurementSystem): number {
        switch (unitType){
            case MeasurementSystem.Imperial:
                return this.calculateBmiFromImperial();
            case MeasurementSystem.Metric:
                return this.calculateBmiFromMetric();
            default:
                return null;
        }
    }

    public isValid(unitType: MeasurementSystem): boolean {
        switch (unitType){
            case MeasurementSystem.Imperial:
                return this.validateImperial();
            case MeasurementSystem.Metric:
                return this.validateMetric();
            default:
                return false;
        }
    }

    private calculateBmiFromImperial(): number {
        const totalInches = this.feet * 12 + this.inches;
        return Bmi.roundBmiResult((this.pounds / (totalInches * totalInches)) * 703);
    }

    private calculateBmiFromMetric(): number {
        const heightMeters = this.centimeters / 100;
        return Bmi.roundBmiResult(this.kilograms / (heightMeters * heightMeters));
    }

    private validateImperial(): boolean {
        return Bmi.validateMeasurementValue(this.feet, Bmi.MinFeet, Bmi.MaxFeet) &&
               Bmi.validateMeasurementValue(this.pounds, Bmi.MinPounds, Bmi.MaxPounds) &&
               Bmi.validateMeasurementValue(this.inches, Bmi.MinInches, Bmi.MaxInches);
    }

    private validateMetric(): boolean {
        return Bmi.validateMeasurementValue(this.centimeters, Bmi.MinCentimeters, Bmi.MaxCentimeters) &&
               Bmi.validateMeasurementValue(this.kilograms, Bmi.MinKilograms, Bmi.MaxKilograms);
    }

    public convertToSurveyAnswer(submitAction: string): any {
        const metricHeight = this.centimeters ? this.centimeters : this.convertHeightToMetric();
        const metricWeight = this.kilograms ? this.kilograms : this.convertWeightToMetric();

        // re-calculate BMI from metric values for consistency in submitted answers
        const answerId = {
            height: metricHeight,
            weight: metricWeight,
            bmi: Bmi.calculateBmi(metricHeight, metricWeight)
        };

        return {
            ResponseType: ResponseType.Bmi,
            AnswerId: answerId,
            Action: submitAction
        };
    }

    private convertHeightToMetric(): number {
        const cms = (this.feet * 12 + this.inches) * 2.54;
        return Math.round(cms);
    }

    private convertWeightToMetric(): number {
        const kgs = this.pounds * 0.45359237;
        return Math.round(kgs);
    }

    get minFeet(): number {
        return Bmi.MinFeet;
    }

    get maxFeet(): number {
        return Bmi.MaxFeet;
    }

    get minInches(): number {
        return Bmi.MinInches;
    }

    get maxInches(): number {
        return Bmi.MaxInches;
    }

    get minPounds(): number {
        return Bmi.MinPounds;
    }

    get maxPounds(): number {
        return Bmi.MaxPounds;
    }

    get minCentimeters(): number {
        return Bmi.MinCentimeters;
    }

    get maxCentimeters(): number {
        return Bmi.MaxCentimeters;
    }

    get minKilograms(): number {
        return Bmi.MinKilograms;
    }

    get maxKilograms(): number {
        return Bmi.MaxKilograms;
    }

    get WeightQuestionId(): string {
        return Bmi.WeightQuestionId;
    }

    get HeightQuestionId(): string {
        return Bmi.HeightQuestionId;
    }

    get BmiResultQuestionId(): string {
        return Bmi.BmiResultQuestionId;
    }
 }
