<!-- Questions -->
<div class="c-survey__section survey-question">
  <h2 class="c-survey__question" *ngFor="let text of questionDetails.QuestionText">{{ text | translate }}</h2>
</div>

<div class="c-survey__section survey-choices">
  <!-- Sleep face label -->
  <div class="c-sleep-icon is-selected-sleepiness">
   <img src="../../assets/images/{{sleepinessSelectedImageFile}}" />
  </div>

  <h3 class="c-sleepiness-choice">
    <span *ngIf="answerText">{{ answerText | translate }}</span>
    <span *ngIf="!answerText">{{ selectYourSleepinessStringId | translate }}</span>
  </h3>

  <!-- Sleep faces -->
  <div class="c-sleepiness-options">
    <div class="o-layout o-layout--small">
      <div class="o-layout__item u-1/5" *ngFor="let answer of questionDetails.PossibleAnswers let i = index">
        <div class="c-sleep-icon">
          <img (click)="this.imageSelected(answer, i)"  src="../../assets/images/{{imageSources[i]}}" />
        </div>
      </div>
    </div>
  </div>
</div>
