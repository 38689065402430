import { Injectable } from "@angular/core";
import { EnvService } from "src/app/env.service";

@Injectable()
export class TranslationProvider {
    private languageMapper = new Map<string, string>();

    constructor(private environmentSettings: EnvService) {
        this.initSupportedLanguages();
    }

    // this maps the language code that the app passes to the code used to load the strings file.
    // for example, both "en" and "en-us" would load the "en-us.json" strings file.
    private initSupportedLanguages(): void {
        // We now support automatic fall back to base locales.  We can still remap languages
        // here within languageMapper, but should avoid it to reduce complexity.
        // See https://confluence.ec2.local/display/SCNG/Languages+and+Locales+Supported#LanguagesandLocalesSupported-LocaleSupport
        this.languageMapper.set('en', 'en-us');  // myAir en is CCI English (United States)
        this.languageMapper.set('en-gb', 'en');  // myAir en-GB is CCI English (ROW)
    }

    public getLocale(languageKey: string): string {
        // Standardize languages.  Automatically fall back to core language if localized not supported.
        // 1. Normalize language provided.  If not provided, DefaultLocale.
        var locale = this.environmentSettings.DefaultLocale;

        var lang = languageKey.toLowerCase();

        if (this.languageMapper.has(lang)) {
            lang = this.languageMapper.get(lang);
        }

        const baseLocale = lang.split('-')[0];

        // 2. Check if exact locale is supported
        if (this.environmentSettings.SupportedLocales.includes(lang)) {
            locale = lang;
        } else if (this.environmentSettings.SupportedLocales.includes(baseLocale)) {
            // Fall back if base locale is supported
            locale = baseLocale;
        }

        if (this.languageMapper.has(locale)) {
            locale = this.languageMapper.get(locale);
        }

        return locale;
    }
}
